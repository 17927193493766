import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import LoginSignup from './pages/loginSignUp';
import { AuthProvider } from './authContext';
import Dashboard from './pages/dashboard/dashboard';
import Register from './pages/registerCandidate';
import BenchCandidates from './pages/benchcandidate/benchCandidates';
import RegisterCandidate from './pages/benchcandidate/registerCandidate';
import UserProfile from './pages/userProfile/userProfile';
import RateCandidates from './pages/rateConfirmation/rateConfirmation';
import MyAssignedCandidates from './pages/myAssignedCandidates/myAssignedCandidates';
import MySubmission from './pages/mysubmission/mySubmission';
import CandidateDetails from './pages/benchcandidate/CandidateDetails';
import CandidateDetailsEdit from './pages/benchcandidate/CandidateDetailsEdit';

// Added 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RecruiterAnalysis from './pages/recruiterAnalysis/RecruiterAnalysis';
import RecruiterAnalysisDetails from './pages/recruiterAnalysis/RecruiterAnalysisDetails';
import { GlobalProvider } from './globalContext';
import RoleRecruiterAnalysis from './pages/roleRecruiterAnalysis/RoleRecruiterAnalysis';
import DetailsRecruiterAnalysis from './pages/roleRecruiterAnalysis/DetailsRecruiterAnalysis';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <GlobalProvider>
          <Router>
            <Routes>
              <Route path='/' element={<LoginSignup />}></Route>
              <Route path='/dashboard' element={<Dashboard />}></Route>
              <Route path='/register' element={<Register />}></Route>
              <Route path='/benchcandidates' element={<BenchCandidates />}></Route>
              <Route path='/details' element={<CandidateDetails/>}></Route>
              <Route path='/registerNewCandidates/' element={<RegisterCandidate />}></Route>
              <Route path='/userProfile' element={<UserProfile />}></Route>
              <Route path='/rateCandidates' element={<RateCandidates />}></Route>
              <Route path='/myAssignedCandidates' element={<MyAssignedCandidates />}></Route>
              <Route path='/mySubmission' element={<MySubmission />}></Route>
              <Route path='/analysisRecruiter' element={<RoleRecruiterAnalysis/>}></Route>
              <Route path='/details' element={<CandidateDetails />}></Route>
              <Route path='/benchcandidates/details/edit' element={<CandidateDetailsEdit />}></Route>
              <Route path='/recruiterAnalysis' element={<RecruiterAnalysis />}></Route>
              <Route path='/analysis' element={<RecruiterAnalysisDetails />}></Route>
              <Route path='/detailsRecruiter' element={<DetailsRecruiterAnalysis/>}></Route>
            </Routes>
          </Router>
          <ToastContainer position="top-center" />
        </GlobalProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
