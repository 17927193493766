import React, { useState, useEffect } from 'react';
import { useAuth } from '../authContext';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../pages/dashboard/4sphereIcon.png";
import DashIcon from "../pages/dashboard/Graph 1.svg";
import SignOutIcon from "../pages/dashboard/Sign Out Icon.svg";

import BenchCandidatesIcon from "./benchCandidatesIcon.svg";
import Rate from "./rate.svg";
import RecruiterCandidatesIcon from "./recruiterAnalysis.svg";
import CollapseIcon from "../components/collapseIcon.svg";

const Dashnav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [navActive, setNavActive] = useState("active");
    const { logout, isLoggedIn, user } = useAuth();

    function goToRegister() {
        navigate("/dashboard");
    }

    function benchCandidatesNavigator() {
        navigate("/benchcandidates");
    }

    function recruiterAnalysis() {
        navigate("/recruiterAnalysis");
    }

    function ratesCandidatesNavigator() {
        navigate("/rateCandidates");
    }

    function myAssignedCandidatesNavigator() {
        navigate("/myAssignedCandidates");
    }

    function mySubmissionNavigator() {
        navigate("/mySubmission");
    }

    function myRecruiterAnalysis() {
        navigate("/analysisRecruiter");
    }

    function myCandidateDetails() {
        navigate("/benchcandidates/details");
    }

    function myCandidateDetailsEdit() {
        navigate("/details/edit");
    }

    function handleLogout() {
        if (isLoggedIn) {
            logout();
            navigate("/");
        }
    }

    useEffect(() => {
        if (!isLoggedIn) {
            const timeout = setTimeout(() => {
                navigate("/");
            }, 500); // 500 ms delay

            return () => clearTimeout(timeout); // Clean up the timeout if the component unmounts
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        if (location.pathname === "/benchcandidates/details") {
            setNavActive("");
        }
        setTimeout(() => {
            setNavActive("");
        }, 6000)
    }, [])

    useEffect(() => {
        if (location.pathname === "/details/edit") {
            setNavActive("");
        }
    }, [])

    function toggleSidenav() {
        setNavActive(navActive === "active" ? "" : "active");
    }

    return (
        <div className={`dashnav ${navActive === "active" ? "active" : ""}`}>
            <div className="sidebar-collapse" onClick={toggleSidenav}>
                <img src={CollapseIcon} alt="icon" />
            </div>
            <div className="dash-inner col-flex">
                <div className="site-logo row-flex">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                <div className="dashnav col-flex">
                    {location.pathname !== "/404" && (
                        <div className={`dash-button row-flex dash ${location.pathname === "/dashboard" ? "active" : ""}`} onClick={goToRegister}>
                            {/* <img src={DashIcon} alt="" className="icon 01" /> */}
                            <svg width="18" height="19" viewBox="0 0 18 19" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.49799 2.00073H12.5022C15.053 2.00073 16.493 3.44748 16.5005 5.99823V13.0032C16.5005 15.5532 15.053 17.0007 12.5022 17.0007H5.49799C2.94724 17.0007 1.50049 15.5532 1.50049 13.0032V5.99823C1.50049 3.44748 2.94724 2.00073 5.49799 2.00073ZM9.03729 13.8957C9.36054 13.8957 9.62979 13.6557 9.65979 13.3332V5.69073C9.68979 5.45823 9.57804 5.22498 9.37554 5.09823C9.16479 4.97073 8.90979 4.97073 8.70804 5.09823C8.50479 5.22498 8.39304 5.45823 8.41479 5.69073V13.3332C8.45304 13.6557 8.72229 13.8957 9.03729 13.8957ZM12.488 13.8957C12.803 13.8957 13.0723 13.6557 13.1105 13.3332V10.8732C13.1323 10.6325 13.0205 10.4082 12.8173 10.2807C12.6155 10.1532 12.3605 10.1532 12.1505 10.2807C11.9473 10.4082 11.8355 10.6325 11.8655 10.8732V13.3332C11.8955 13.6557 12.1648 13.8957 12.488 13.8957ZM6.16476 13.3332C6.13476 13.6557 5.86551 13.8957 5.54226 13.8957C5.21976 13.8957 4.94976 13.6557 4.92051 13.3332V8.15073C4.89801 7.91748 5.00976 7.68573 5.21301 7.55823C5.41476 7.43073 5.67051 7.43073 5.87301 7.55823C6.07476 7.68573 6.18801 7.91748 6.16476 8.15073V13.3332Z" fill="inherit" />
                            </svg>
                            <span>Dashboard</span>
                        </div>
                    )}


                    {location.pathname !== "/404" && (
                        <div className={`dash-button row-flex ${location.pathname === "/benchcandidates" ? "active" : ""}
                         ${user?.user_role === "recruiter" ? "hidden" : "show"}`} onClick={benchCandidatesNavigator}>
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0974 5.96878C13.0974 8.17155 11.3313 9.93782 9.12693 9.93782C6.92336 9.93782 5.15644 8.17155 5.15644 5.96878C5.15644 3.76601 6.92336 2.00049 9.12693 2.00049C11.3313 2.00049 13.0974 3.76601 13.0974 5.96878ZM9.12695 17.0005C5.87373 17.0005 3.12695 16.4717 3.12695 14.4317C3.12695 12.3909 5.89099 11.8809 9.12695 11.8809C12.3809 11.8809 15.127 12.4097 15.127 14.4497C15.127 16.4905 12.3629 17.0005 9.12695 17.0005Z" fill="inherit" />
                            </svg>

                            <span>Bench Candidates</span>
                        </div>
                    )}

                    {location.pathname !== "/404" && (
                        <div className={`dash-button row-flex ${location.pathname === "/rateCandidates" ? "active" : ""} ${user?.user_role === "recruiter" ? "hidden" : "show"}`} onClick={ratesCandidatesNavigator}>
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.12068 2.32587H12.4064C14.7232 2.32587 16.0132 3.66087 16.0132 5.94837V13.6959C16.0132 16.0209 14.7232 17.3259 12.4064 17.3259H6.12068C3.84068 17.3259 2.51318 16.0209 2.51318 13.6959V5.94837C2.51318 3.66087 3.84068 2.32587 6.12068 2.32587ZM6.32329 5.82087V5.81337H8.56504C8.88829 5.81337 9.15079 6.07587 9.15079 6.39762C9.15079 6.72837 8.88829 6.99087 8.56504 6.99087H6.32329C6.00004 6.99087 5.73829 6.72837 5.73829 6.40587C5.73829 6.08337 6.00004 5.82087 6.32329 5.82087ZM6.32329 10.3809H12.2033C12.5258 10.3809 12.7883 10.1184 12.7883 9.79587C12.7883 9.47337 12.5258 9.21012 12.2033 9.21012H6.32329C6.00004 9.21012 5.73829 9.47337 5.73829 9.79587C5.73829 10.1184 6.00004 10.3809 6.32329 10.3809ZM6.3232 13.8084H12.2032C12.5025 13.7784 12.7282 13.5226 12.7282 13.2234C12.7282 12.9159 12.5025 12.6609 12.2032 12.6309H6.3232C6.0982 12.6084 5.8807 12.7134 5.7607 12.9084C5.6407 13.0959 5.6407 13.3434 5.7607 13.5384C5.8807 13.7259 6.0982 13.8384 6.3232 13.8084Z" fill="inherit" />
                            </svg>

                            <span>Rate Confirmations</span>
                        </div>
                    )}


                    {location.pathname !== "/404" && (
                        <div className={`dash-button row-flex ${location.pathname === "/recruiterAnalysis" ? "active" : ""}
                         ${user?.user_role === "recruiter" ? "hidden" : "show"}`} onClick={recruiterAnalysis}>
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M15.0417 8.25C15.4412 8.24988 15.8259 8.3928 16.1188 8.65012C16.4118 8.90744 16.5912 9.26013 16.6211 9.6375L16.6251 9.75V14.25C16.6252 14.6284 16.4743 14.9929 16.2027 15.2704C15.9311 15.5479 15.5588 15.7179 15.1605 15.7463L15.0417 15.75H11.8751C11.4756 15.7501 11.0909 15.6072 10.798 15.3499C10.5051 15.0926 10.3257 14.7399 10.2957 14.3625L10.2917 14.25V9.75C10.2916 9.37157 10.4425 9.00707 10.7141 8.72959C10.9857 8.4521 11.358 8.28213 11.7563 8.25375L11.8751 8.25H15.0417ZM15.0417 2.25C15.4617 2.25 15.8644 2.40804 16.1613 2.68934C16.4583 2.97064 16.6251 3.35218 16.6251 3.75V5.25C16.6251 5.64782 16.4583 6.02936 16.1613 6.31066C15.8644 6.59196 15.4617 6.75 15.0417 6.75H11.8751C11.4552 6.75 11.0524 6.59196 10.7555 6.31066C10.4586 6.02936 10.2917 5.64782 10.2917 5.25V3.75C10.2917 3.35218 10.4586 2.97064 10.7555 2.68934C11.0524 2.40804 11.4552 2.25 11.8751 2.25H15.0417Z" fill="inherit" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.125 2.25C7.54493 2.25 7.94765 2.40804 8.24459 2.68934C8.54152 2.97064 8.70833 3.35218 8.70833 3.75V8.25C8.70833 8.64782 8.54152 9.02936 8.24459 9.31066C7.94765 9.59196 7.54493 9.75 7.125 9.75H3.95833C3.53841 9.75 3.13568 9.59196 2.83875 9.31066C2.54181 9.02936 2.375 8.64782 2.375 8.25V3.75C2.375 3.35218 2.54181 2.97064 2.83875 2.68934C3.13568 2.40804 3.53841 2.25 3.95833 2.25H7.125Z" fill="inherit" />
                                <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M7.125 11.25C7.54493 11.25 7.94765 11.408 8.24459 11.6893C8.54152 11.9706 8.70833 12.3522 8.70833 12.75V14.25C8.70833 14.6478 8.54152 15.0294 8.24459 15.3107C7.94765 15.592 7.54493 15.75 7.125 15.75H3.95833C3.53841 15.75 3.13568 15.592 2.83875 15.3107C2.54181 15.0294 2.375 14.6478 2.375 14.25V12.75C2.375 12.3522 2.54181 11.9706 2.83875 11.6893C3.13568 11.408 3.53841 11.25 3.95833 11.25H7.125Z" fill="inherit" />
                            </svg>

                            <span>Recruiter Analysis</span>
                        </div>
                    )}


                    <div className={`dash-button row-flex dash ${location.pathname === "/myAssignedCandidates" ? "active" : ""} ${user?.user_role === "recruiter" ? "show" : "hidden"}`} onClick={myAssignedCandidatesNavigator}>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0974 5.96878C13.0974 8.17155 11.3313 9.93782 9.12693 9.93782C6.92336 9.93782 5.15644 8.17155 5.15644 5.96878C5.15644 3.76601 6.92336 2.00049 9.12693 2.00049C11.3313 2.00049 13.0974 3.76601 13.0974 5.96878ZM9.12695 17.0005C5.87373 17.0005 3.12695 16.4717 3.12695 14.4317C3.12695 12.3909 5.89099 11.8809 9.12695 11.8809C12.3809 11.8809 15.127 12.4097 15.127 14.4497C15.127 16.4905 12.3629 17.0005 9.12695 17.0005Z" fill="inherit" />
                        </svg>
                        <span>My Assigned Candidates</span>
                    </div>

                    <div className={`dash-button row-flex dash ${location.pathname === "/mySubmission" ? "active" : ""} ${user?.user_role === "recruiter" ? "show" : "hidden"}`} onClick={mySubmissionNavigator}>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.12068 2.32587H12.4064C14.7232 2.32587 16.0132 3.66087 16.0132 5.94837V13.6959C16.0132 16.0209 14.7232 17.3259 12.4064 17.3259H6.12068C3.84068 17.3259 2.51318 16.0209 2.51318 13.6959V5.94837C2.51318 3.66087 3.84068 2.32587 6.12068 2.32587ZM6.32329 5.82087V5.81337H8.56504C8.88829 5.81337 9.15079 6.07587 9.15079 6.39762C9.15079 6.72837 8.88829 6.99087 8.56504 6.99087H6.32329C6.00004 6.99087 5.73829 6.72837 5.73829 6.40587C5.73829 6.08337 6.00004 5.82087 6.32329 5.82087ZM6.32329 10.3809H12.2033C12.5258 10.3809 12.7883 10.1184 12.7883 9.79587C12.7883 9.47337 12.5258 9.21012 12.2033 9.21012H6.32329C6.00004 9.21012 5.73829 9.47337 5.73829 9.79587C5.73829 10.1184 6.00004 10.3809 6.32329 10.3809ZM6.3232 13.8084H12.2032C12.5025 13.7784 12.7282 13.5226 12.7282 13.2234C12.7282 12.9159 12.5025 12.6609 12.2032 12.6309H6.3232C6.0982 12.6084 5.8807 12.7134 5.7607 12.9084C5.6407 13.0959 5.6407 13.3434 5.7607 13.5384C5.8807 13.7259 6.0982 13.8384 6.3232 13.8084Z" fill="inherit" />
                        </svg>
                        <span>My submissions</span>
                    </div>

                    <div className={`dash-button row-flex dash ${location.pathname === "/analysisRecruiter" ? "active" : ""} ${user?.user_role === "recruiter" ? "show" : "hidden"}`} onClick={myRecruiterAnalysis}>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M15.0417 8.25C15.4412 8.24988 15.8259 8.3928 16.1188 8.65012C16.4118 8.90744 16.5912 9.26013 16.6211 9.6375L16.6251 9.75V14.25C16.6252 14.6284 16.4743 14.9929 16.2027 15.2704C15.9311 15.5479 15.5588 15.7179 15.1605 15.7463L15.0417 15.75H11.8751C11.4756 15.7501 11.0909 15.6072 10.798 15.3499C10.5051 15.0926 10.3257 14.7399 10.2957 14.3625L10.2917 14.25V9.75C10.2916 9.37157 10.4425 9.00707 10.7141 8.72959C10.9857 8.4521 11.358 8.28213 11.7563 8.25375L11.8751 8.25H15.0417ZM15.0417 2.25C15.4617 2.25 15.8644 2.40804 16.1613 2.68934C16.4583 2.97064 16.6251 3.35218 16.6251 3.75V5.25C16.6251 5.64782 16.4583 6.02936 16.1613 6.31066C15.8644 6.59196 15.4617 6.75 15.0417 6.75H11.8751C11.4552 6.75 11.0524 6.59196 10.7555 6.31066C10.4586 6.02936 10.2917 5.64782 10.2917 5.25V3.75C10.2917 3.35218 10.4586 2.97064 10.7555 2.68934C11.0524 2.40804 11.4552 2.25 11.8751 2.25H15.0417Z" fill="inherit" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.125 2.25C7.54493 2.25 7.94765 2.40804 8.24459 2.68934C8.54152 2.97064 8.70833 3.35218 8.70833 3.75V8.25C8.70833 8.64782 8.54152 9.02936 8.24459 9.31066C7.94765 9.59196 7.54493 9.75 7.125 9.75H3.95833C3.53841 9.75 3.13568 9.59196 2.83875 9.31066C2.54181 9.02936 2.375 8.64782 2.375 8.25V3.75C2.375 3.35218 2.54181 2.97064 2.83875 2.68934C3.13568 2.40804 3.53841 2.25 3.95833 2.25H7.125Z" fill="inherit" />
                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M7.125 11.25C7.54493 11.25 7.94765 11.408 8.24459 11.6893C8.54152 11.9706 8.70833 12.3522 8.70833 12.75V14.25C8.70833 14.6478 8.54152 15.0294 8.24459 15.3107C7.94765 15.592 7.54493 15.75 7.125 15.75H3.95833C3.53841 15.75 3.13568 15.592 2.83875 15.3107C2.54181 15.0294 2.375 14.6478 2.375 14.25V12.75C2.375 12.3522 2.54181 11.9706 2.83875 11.6893C3.13568 11.408 3.53841 11.25 3.95833 11.25H7.125Z" fill="inherit" />
                        </svg>
                        <span>Recruiter Analysis</span>
                    </div>

                    <div className="dash-button row-flex" onClick={handleLogout}>
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.4064 0.3797H4.12068C1.84068 0.3797 0.513184 1.7147 0.513184 4.0022V11.7497C0.513184 14.0747 1.84068 15.3797 4.12068 15.3797H10.4064C12.7232 15.3797 14.0132 14.0747 14.0132 11.7497V4.0022C14.0132 1.7147 12.7232 0.3797 10.4064 0.3797Z" fill="inherit" />
                            <g clip-path="url(#clip0_744_235)">
                                <path d="M5.25684 8.31721H8.57568C8.69172 8.31721 8.80299 8.27112 8.88504 8.18907C8.96709 8.10702 9.01318 7.99574 9.01318 7.87971C9.01318 7.76368 8.96709 7.6524 8.88504 7.57035C8.80299 7.4883 8.69172 7.44221 8.57568 7.44221H5.25684L5.82251 6.87654C5.90417 6.79442 5.94994 6.68327 5.94978 6.56746C5.94962 6.45165 5.90354 6.34063 5.82165 6.25874C5.73976 6.17685 5.62874 6.13078 5.51293 6.13062C5.39712 6.13045 5.28597 6.17622 5.20386 6.25788L3.89136 7.57038C3.85072 7.611 3.81849 7.65922 3.7965 7.7123C3.7745 7.76537 3.76318 7.82226 3.76318 7.87971C3.76318 7.93716 3.7745 7.99405 3.7965 8.04713C3.81849 8.1002 3.85072 8.14842 3.89136 8.18904L5.20386 9.50154C5.24444 9.54235 5.29267 9.57474 5.34579 9.59688C5.39892 9.61901 5.45588 9.63045 5.51343 9.63053C5.57098 9.63061 5.62799 9.61933 5.68117 9.59734C5.73435 9.57536 5.78268 9.54309 5.82337 9.5024C5.86407 9.46171 5.89633 9.41338 5.91832 9.3602C5.9403 9.30701 5.95158 9.25001 5.9515 9.19246C5.95142 9.13491 5.93998 9.07794 5.91785 9.02482C5.89572 8.9717 5.86332 8.92346 5.82251 8.88288L5.25684 8.31721Z" fill="#DADADA" />
                                <path d="M10.3257 4.3797H6.82568C6.76822 4.37967 6.71132 4.39096 6.65822 4.41294C6.60513 4.43491 6.55688 4.46714 6.51625 4.50777C6.47562 4.5484 6.4434 4.59664 6.42142 4.64974C6.39945 4.70283 6.38815 4.75974 6.38818 4.8172V5.6922C6.38818 5.80823 6.43428 5.91951 6.51632 6.00156C6.59837 6.08361 6.70965 6.1297 6.82568 6.1297C6.94172 6.1297 7.053 6.08361 7.13504 6.00156C7.21709 5.91951 7.26318 5.80823 7.26318 5.6922V5.2547H9.88818V10.5047H7.26318V10.0672C7.26318 9.95117 7.21709 9.83989 7.13504 9.75784C7.053 9.67579 6.94172 9.6297 6.82568 9.6297C6.70965 9.6297 6.59837 9.67579 6.51632 9.75784C6.43428 9.83989 6.38818 9.95117 6.38818 10.0672V10.9422C6.38815 10.9997 6.39945 11.0566 6.42142 11.1097C6.4434 11.1628 6.47562 11.211 6.51625 11.2516C6.55688 11.2923 6.60513 11.3245 6.65822 11.3465C6.71132 11.3684 6.76822 11.3797 6.82568 11.3797H10.3257C10.3831 11.3797 10.4401 11.3684 10.4931 11.3465C10.5462 11.3245 10.5945 11.2923 10.6351 11.2516C10.6757 11.211 10.708 11.1628 10.7299 11.1097C10.7519 11.0566 10.7632 10.9997 10.7632 10.9422V4.8172C10.7632 4.75974 10.7519 4.70283 10.7299 4.64974C10.708 4.59664 10.6757 4.5484 10.6351 4.50777C10.5945 4.46714 10.5462 4.43491 10.4931 4.41294C10.4401 4.39096 10.3831 4.37967 10.3257 4.3797Z" fill="#DADADA" />
                            </g>
                            <defs>
                                <clipPath id="clip0_744_235">
                                    <rect width="7" height="7" fill="white" transform="translate(3.76318 4.3797)" />
                                </clipPath>
                            </defs>
                        </svg>


                        <span>Logout</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashnav;
